import React, { Suspense } from "react"

import { isSSR } from "../utils/constants"
import Layout from "../components/layout"
import SEO from "../components/Seo"
import Spinner from "../components/Spinner"

const SimulatorAmortization = React.lazy(() =>
  import("../components/AmortizationCalculator")
)

export default () => {
  return (
    <>
      {!isSSR && (
        <Suspense fallback={<Spinner />}>
          <SEO
            title="Simulador de plan de pago"
            keywords={["Iban Online", "Iban Online"]}
            description=""
          />
          <Layout>
            <SimulatorAmortization />
          </Layout>
        </Suspense>
      )}
    </>
  )
}
